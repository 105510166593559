import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Alert`}</h1>
    <p>{`Alert is an unobtrusive, yet highly visible
way to display important information and
provide feedback to user action.`}</p>
    <ComponentPreview componentName="alert--success" hasReact hasAngular hasHTML titleAttr="Alert Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use to direct attention to notable information, or to give feedback after an action.`}</p>
    <p>{`Example use cases:`}</p>
    <ul>
      <li parentName="ul">{`Show an alert after successful form submission.`}</li>
      <li parentName="ul">{`Direct to a part of the page that needs attention.`}</li>
      <li parentName="ul">{`Notify users of critical information.`}</li>
      <li parentName="ul">{`Inform a user with a dismissible Alert.`}</li>
    </ul>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Place above the main content of the page, but below the Masthead.`}</li>
      <li parentName="ul">{`Use a clear and concise message that communicates one piece of information per Alert.`}</li>
      <li parentName="ul">{`The message can contain a call to action through a link. Do not use buttons.`}</li>
      <li parentName="ul">{`Alerts can be permanent or dismissed. If dismissable, choose how long it will stay on the page and if it's dismissable through the Close Icon.`}</li>
      <li parentName="ul">{`Do not use on top of sidebars or narrow elements.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Information Alert`}</h3>
    <p>{`This alert has a Bell Icon with critical information. These alerts are typically already on the page when the user arrives.`}</p>
    <ComponentPreview componentName="alert--info" hasReact hasAngular hasHTML titleAttr="Information Alert Example" mdxType="ComponentPreview" />
    <h3>{`Success Alert`}</h3>
    <p>{`This Alert has a Checkmark Icon and provides positive feedback to user action.`}</p>
    <ComponentPreview componentName="alert--success" hasReact hasAngular hasHTML titleAttr="Success Alert Example" mdxType="ComponentPreview" />
    <h3>{`Fail Alert`}</h3>
    <p>{`This Alert has an Exclamation Icon with negative feedback to user action.`}</p>
    <ComponentPreview componentName="alert--fail" hasReact hasAngular hasHTML titleAttr="Fail Alert Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Alert must have an Icon on the left side.`}</li>
      <li parentName="ul">{`Alert must have a clear, concise piece of text that may contain Links.`}</li>
      <li parentName="ul">{`Alert may have a Close Icon to dismiss the Alert.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      